.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.MuiIconButton-root {
  margin-top: 3px !important;
  margin-left: 2px !important;
}

.MuiIconButton-root.Mui-checked {
  margin-top: 1.3px !important;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em !important;
  display: flex;
  align-items: center;
  color: #303030 !important;
  margin-left: 10px !important;
  padding-top: 5px !important;
}

.job-type-container {
  padding-left: 10px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #6a67ce !important;
}

.date-posted .MuiFormControlLabel-root {
  width: 45%;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #6a67ce !important;
}

.btn-container button {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center;
  letter-spacing: 0.02em;
  line-height: 17px;
  height: 40px;
  border-radius: 31.5px;
}

.btn-container .btn-primary {
  background: #6a67ce;
  color: #fff;
}

.btn-container .btn-secondary {
  border: 1px solid #6a67ce;
  color: #6a67ce;
  margin-left: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.sidebar .icon {
  z-index: 99;
}

.select__menu {
  z-index: 999 !important;
}
