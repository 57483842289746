.login-btn {
  width: 110px;
  height: 40px;
  background: #eaf2ef !important;
  border-radius: 31.5px !important;
  font-family: GothamMedium !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 40px !important;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030 !important;
}

@media only screen and (max-width: 480px) {
  .mob-pad-24 {
    padding-left: 24px !important;
  }
}

@media only screen and ( 480px < width <960px) {
 .bottom-text-account{
  margin-left: 7.5%;
 }
 
}