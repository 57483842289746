.footer-div {
  background: #ecf4ff;
  height: fit-content !important;
  padding-top: 20px;
  overflow: hidden;
}

.footer-desktop {
  justify-content: space-between !important;
}

.footer-div .mailto:hover {
  color: #6a67ce !important;
}

.footer-sub-text:hover {
  color: #6a67ce !important;
}

.call-us-text {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: black;
}
.phone-no-text {
  font-family: GothamBook;
  font-size: 16px;
  line-height: 30px;
  align-items: center;
  letter-spacing: 0.05em;
  color: black;
}
.address-text {
  font-family: GothamBook;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.05em;
  color: black;
}

.email-text {
  font-family: GothamMedium !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  align-items: center;
  letter-spacing: 0.05em;
  color: black !important;
}

.email-text a {
  color: black !important;
}

.footer-text {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  letter-spacing: 0.05em;
  color: black;
}
.footer-sub-text {
  font-family: GothamBook;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  letter-spacing: 0.05em;
  color: black;
}

.rights-res-text {
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.05em;
  color: black;
}

.rights-symbol {
  font-weight: bold;
  font-size: large;
}

.bottom-line {
  width: 100% !important;
}

hr.bottom-line {
  border: 0.5px solid #bdbdbd !important;
}

@media only screen and (max-width: 1025px) {
  .footer-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 480px) {
  .footer-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer-div {
    display: block;
    height: 140px;
  }
}

@media screen and (min-width: 1025px) {
  .footer-mobile {
    display: none !important;
  }
}
