.AppWrapper {
  position: relative;
}

.Form {
  animation: fade 200ms ease-out;
  margin-top: 30px;
}

.FormGroup {
  /* margin: 0 15px; */
  padding: 0;
  border: 1px solid #000000 !important;
  background-color: #ffffff;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffffff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #000000;
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #c4f0ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #000000;
  transition: background-color 100000000s;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #fff;
  background-color: transparent;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.SubmitButton {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100%;
  height: 50px;
  margin: 40px 0;
  background-color: #504294;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #504294;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  border: none;
}

.SubmitButton:active {
  background-color: #504294;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #504294;
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #504294;
  box-shadow: none;
}

.ErrorMessage {
  color: #91171d;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}

.coupon-container {
  display: flex;
  align-items: center;
  position: relative;
}

.coupon-input fieldset {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.apply-coupon-btn {
  background: #6a67ce !important;
  color: #fff !important;
  opacity: 0.7;
  height: 56px;
  font-size: 14px !important;
  text-transform: inherit !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.apply-coupon-btn:hover {
  background: #6a67ce !important;
  opacity: 1;
}

.remove-coupon {
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}

.remove-coupon:hover {
  text-decoration: underline;
}

.total-amount {
  display: flex;
  justify-content: space-between;
}
