.noclass {
  display: none;
}

.about-background-video {
  position: relative;
  overflow: hidden;
}

video {
  object-fit: cover;
  z-index: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
