.job-details .banner {
  height: 201px !important;
  padding: 5%;
  padding-top: 3%;
}

.job-container {
  padding: 5%;
}

.job-details .job-list-item-footer {
  margin-top: 10px;
}

.banner-right {
  display: flex;
  justify-content: flex-end;
}

.copy {
  padding: 0 25px;
}

.job-description {
  margin-bottom: 60px;
  margin-top: 20px;
}

.job-description p {
  font-weight: normal;
  font-style: normal;
  color: #303030;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.05em;
}

.job-overview {
  background: #eaf2ef;
  padding: 4% 2% !important;
  border-radius: 10px;
  height: max-content;
}

.job-overview-content-title {
  font-weight: bold !important;
  font-style: normal;
  color: #303030;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.02em !important;
  text-transform: capitalize;
}

.job-overview-content-body {
  font-weight: normal !important;
  font-style: normal;
  color: #303030;
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em !important;
  margin-left: 7% !important;
  margin-top: 1% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.map-content {
  height: 400px;
}

.btn-container a {
  text-decoration: none !important;
}

.backIcon {
  padding-right: 5px;
  margin-left: -3%;
  margin-right: 3%;
}

.job-html button {
  display: none !important;
}

.job-html img {
  display: none !important ;
}

.job-html {
  font-weight: normal !important;
  font-style: normal !important;
  color: #303030 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em;
}

/* Media Queries */
@media screen and (max-width: 480px) {
  .job-description p {
    font-size: 14px;
  }

  .job-html {
    font-size: 14px !important;
  }

  .copy {
    padding: 0 5px;
  }

  .job-details .btn-container button {
    font-size: 14px !important;
  }

  .job-details .banner {
    align-items: start !important;
    height: max-content !important;
  }

  .banner-right {
    padding-top: 2%;
  }

  .job-details .job_address {
    width: max-content !important;
  }

  .job-overview {
    padding: 10px 15px !important;
  }

  .backIcon {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .job-details .banner {
    height: 250px !important;
    padding-top: 3.4%;
  }
}

@media screen and (min-width: 760px) and (max-width: 1270px) {
  .job-details .banner {
    height: max-content !important;
  }
}

.gmnoprint {
  display: none !important;
}

.gm-fullscreen-control {
  display: none !important;
}
