.back-color .MuiDrawer-paper {
  background-color: white;
}
.col2-mar {
  margin-top: 0px !important;
}

.pad-b-drawer {
  padding-bottom: 92px;
}

.ham-menu-close {
  padding: 26px 0px 0px 2px;

  text-align-last: right;
}

.app-drawer-close-btn {
  height: 30px;
  width: 30px;
}

.draw-flex {
  display: flex;
}

.draw-cs-ml {
  margin-left: auto;
  padding-right: 105px;
  padding-bottom: 50px;
}

.col1-font {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: initial !important;
  color: #000000;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.col-padding.MuiListItem-root {
  padding-bottom: 12px !important;
  border-bottom: 0.5px solid #e2e2e2;
  padding-top: 12px !important;
}

.col2-font {
  font-family: URW DIN SemiCond;
  font-size: 30px;
  line-height: 147%;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
}

.col1-mar {
  padding-left: 27px !important;
}
.menu-tm {
  margin-top: 45px;
}

.side-drawer-pad {
  padding-left: 60px;
  padding-right: 60px;
}

.menu-icon-dimensions {
  width: 37px;
  height: 30px;
}

@media only screen and (max-width: 768px) {
  .draw-flex {
    display: block;
  }
  .draw-cs-ml {
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 50px;
  }
  .pad-b-drawer {
    padding-bottom: 20px;
  }
  .left {
    display: inline-block !important;
  }
  .top {
    display: none !important;
  }
  .back-color .MuiDrawer-paper {
    width: 195px !important;
    height: 100% !important;
    left: 0px !important;
    top: 0px !important;
    background: #ffffff !important;
    box-shadow: 1px 2px 20px 1px rgb(180 180 180 / 15%) !important;
    border-radius: 0px 0px 50px 0px !important;
  }
  .col2-mar {
    margin-top: 80px !important;
  }

  .app-drawer-close-btn {
    height: 21.84px;
    width: 21.84px;
  }
  .side-drawer-pad {
    padding-left: 20px;
    padding-right: 20px;
  }
  .menu-icon-dimensions {
    width: 25px;
    height: 20px;
  }
  .col1-mar {
    padding-left: 0px !important;
  }
}

@media screen and (width<1025px) {
  .logo {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  
}
@media screen and (960px < width <1026px) {

  
  .col1-mar{
    width: max-content;
  }
 
}