.slider-container {
  background: #6a67ce;
  border-radius: 20px;
  padding: 20px;
  height: 200px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-direction: column;
}

.slider-review {
  font: 400 italic 14px/26px Gotham !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.slider-name {
  font: 700 14px/30px Gotham !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #eaf2ef;
}

.swiper-button-prev {
  left: 20% !important;
  top: 50% !important;
}

.swiper-button-next {
  right: 20% !important;
  top: 50% !important;
}

.swiper-pagination {
  bottom: 0% !important;
}

@media screen and (width < 960px) {
  .swiper-button-prev {
    left: 2% !important;
    top: 42% !important;
  }

  .swiper-button-next {
    right: 2% !important;
    top: 42% !important;
  }

  .swiper-pagination {
    bottom: 0% !important;
  }

  .slider-container {
    height: 220px;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 40px !important;
  }
  .mob-padding{
    justify-content: space-around;
  }
}

@media screen and (width < 600px) {
  .swiper-button-prev {
    top: 50% !important;
  }

  .swiper-button-next {
    top: 50% !important;
  }
}

@media screen and (width < 480px) {
  .swiper-button-prev {
    left: 1% !important;
  }

  .swiper-button-next {
    right: 1% !important;
  }

  .slider-container {
    height: 250px;
  }

  .slider-review {
    font: 400 italic 12px/24px Gotham !important;
  }

  .slider-name {
    font: 700 12px/26px Gotham !important;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 30px !important;
  }
}
