.main-header .MuiAppBar-colorPrimary {
  color: black !important;
  background-color: #ecf4ff !important;
  padding-bottom: 14px !important;
}

.header-div {
  margin-left: auto;
  margin-right: 52px;
  margin-top: 22px;
}

.main-header .MuiPaper-elevation4 {
  box-shadow: none !important;
}

.contact-main-header .MuiAppBar-colorPrimary {
  color: black !important;
  background-color: white !important;
}

.contact-main-header .MuiPaper-elevation4 {
  box-shadow: none !important;
}

.route-link:hover {
  color: #6a67ce !important;
}

@media only screen and (max-width: 1025px) {
  .header-div {
    display: none !important;
  }
}
.MuiAppBar-positionFixed {
  position: initial !important;
}

.MuiTouchRipple-rippleVisible {
  opacity: 0 !important;
  animation: none !important;
}
