.dashboard .MuiAppBar-colorPrimary {
  color: black;
  background: white;
}

.dashboard .MuiToolbar-regular {
  min-height: 88px;
}

.dashboard .header-div a {
  padding-left: 28px !important;
}

.dashboard .MuiButton-label {
  font-size: 20px !important;
  font-weight: normal !important;
  font-style: normal !important;
}

.default-user {
  height: 46px;
  width: 46px;
  vertical-align: middle;
  margin-left: 13px;
  border-radius: 50%;
}

.dashboard header {
  box-shadow: none !important;
}

.dashboard.profile .MuiAppBar-colorPrimary {
  background: #ecf4ff;
}

.dropdown-icon {
  height: 10px;
  width: 10px;
  /* margin-left: 5px; */
 
}
.dropdown-icon-div{
  margin-top:-24px
}
@media screen and (max-width: 480px) {
  .default-user {
    margin: 10px auto;
  }
  .dashboard.profile .MuiList-root {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: -10px 48.59671401977539px 140px 0px #7e7ba033 !important;
    border-radius: 10px !important;
  }

  .menu-list-item {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #000 !important;
    font-weight: 700 !important;
  }

  .menu-list-item::before {
    border-bottom: 1px solid #e2e2e2 !important;
    content: "";
    position: absolute;
    left: 10%;
    bottom: 0;
    height: 1px;
    width: 80%;
  }

  .menu-list-item:hover {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .menu-list-close:hover {
    text-decoration: none !important;
    background: none !important;
  }

  .menu-list-item.active-link {
    color: #3be8b0 !important;
  }
  .dropdown-icon-div{
    margin-top: -35px;
    margin-left: -15px;
  }
}
@media screen and ( min-width :1026px) {

  
  .tablet-view-profile{
    display: none !important;
  }
  .default-user{
    border: 1rem rebeccapurple;
  }
  .dropdown-icon-div{
    display: none !important;
  }
 }