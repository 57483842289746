.profile-tabs {
  width: 100%;
  margin-top: 40px;
}

.profile-tabs .Mui-selected {
  color: #6a67ce !important;
}

.logout-tab {
  font-size: 16px !important;
  color: #6a67ce;
  padding-top: 13px;
  cursor: pointer;
  font-weight: 700 !important;
  padding-left: 10px;
}

.MuiTabs-indicator {
  display: none !important;
}

.MuiTabs-flexContainer {
  justify-content: center;
  margin-bottom: 5px;
}

.Mui-selected .MuiTab-wrapper:before {
  content: "";
  position: absolute;
  left: 10%;
  bottom: 0;
  height: 1px;
  width: 80%;
  border-bottom: 2px solid #6a67ce !important;
}

.profile-tab-banner {
  height: 184px;
  background: #eaf2ef;
  padding: 0;
  margin: 0;
  position: relative;
}

.profile {
  padding: 0 0 !important;
}

.profile-tab-content {
  padding-bottom: 50px;
  text-align: center;
}

.userprofileimg {
  margin-top: -13% !important;
  position: relative;
}

.userprofileimg img {
  height: 104px;
  width: 104px;
  border-radius: 50%;
}

.userprofileimg > span {
  height: 104px !important;
  width: 104px !important;
}

.userprofileimg1 {
  display: flex;
  justify-content: center;
}

.edit-profile {
  font-size: 12px !important;
  color: #6a67ce !important;
  cursor: pointer;
  margin-top: 45px !important;
}

.profile-country-div {
  justify-content: center;
  display: flex;
  position: relative;
  text-align: start !important;
}

.icon-2 {
  left: 18%;
}

.add-icon {
  position: absolute;
  bottom: 10%;
  right: 41%;
  cursor: pointer;
  height: 28px !important;
  width: 28px !important;
}

.profile-inputs {
  width: 65%;
}

.profile-inputs .MuiInputBase-root {
  height: 45px !important;
}

.profile-inputs label {
  font-size: 16px !important;
  padding: 3px !important;
}

.profile-inputs input {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 30px !important;
}

.edit-pr {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.p-2 {
  padding: 2%;
}

.save-image .react-responsive-modal-modal {
  height: max-content !important;
  width: 70% !important;
}

.save-image .reactEasyCrop_Container {
  height: 300px !important;
  margin-top: 100px !important;
}
.reactEasyCrop_Image {
  height: 400px !important;
}

.img-controls {
  margin-top: 35%;
  padding: 3%;
}

.logout-modal .react-responsive-modal-modal {
  width: 40% !important;
}

.terms-heading1 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 46px;
  /* identical to box height */

  text-transform: capitalize;

  /* VisaBridge/Primary */

  color: #6a67ce;
  margin-right: -7%;
}

.payment-details-padding {
  margin-right: -21%;
}

.swiper {
  margin-right: 0;
  margin-left: 0;
}

.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-button-next,
.swiper-button-prev {
  top: 40%;
}

.swiper-slide {
  width: 100% !important;
}

/* Media Queries */
@media screen and (max-width: 480px) {
  .icon-2 {
    left: 5%;
  }

  .profile-inputs {
    width: 90%;
  }

  .add-icon {
    right: 35%;
  }

  .profile-tabs {
    margin-top: 0px;
  }

  .profile-tab-banner {
    height: 120px;
    background: #ecf4ff;
  }

  .userprofileimg img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  .edit-profile {
    margin-top: 15px !important;
  }

  .terms-heading1 {
    margin-right: 0%;
  }

  .payment-details-padding {
    margin-right: 0%;
  }

  .saved-cards {
    padding: 5% 5%;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 70%;
  }
}

.change-password .signup-input-field {
  max-width: 100% !important;
  width: 100% !important;
}

.h-20 {
  height: 20px;
}

.change-password .btn-container .btn-primary {
  padding: 22px;
}

.change-password > div {
  width: 40%;
}

.saved-cards {
  background: #ecf4ff;
  overflow: hidden;
  padding-top: 5%;
  max-height: max-content;
  overflow-y: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

#card {
  height: 200px;
  width: 75%;
  max-width: 430px;
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-position: center;
  color: #eee;
  filter: drop-shadow(1px 3px 10px #222);
  animation: fadeIn 0.2s linear 1;
  position: relative;
}

#card:hover {
  opacity: 0.7;
}

#card:active {
  opacity: 0.7;
}

@keyframes fadeIn {
  from {
    top: -150px;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.defaultCard {
  opacity: 1 !important;
}

#card .header {
  display: flex;
  justify-content: space-between;
  height: 70px;
}

#card .sticker {
  width: 50px;
  height: 40px;
  background-image: url("https://images.pexels.com/photos/728471/pexels-photo-728471.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500");
  background-position: center;
  background-size: cover;
  object-fit: contain;
  border-radius: 5px;
}

#card .logo {
  max-width: 120px;
}

#card .body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60%;
}

#card .footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}

.remove-card {
  position: absolute;
  top: 40%;
  left: 45%;
}

.remove-card img {
  height: 45px;
  opacity: 1 !important;
}

.payment-details {
  display: flex;
  justify-content: center;
}

.payment-details div:nth-child(1) {
  flex: 1;
  display: flex;
  justify-content: center;
  transform: translateX(10px);
}

.card-container {
  display: flex;
  justify-content: center;
}

.select__placeholder {
  color: #939393 !important;
}

.invoice-pagination {
  margin-top: 20px !important;
}

.has-prev {
  opacity: 0.5;
}

.has-next {
  opacity: 0.5;
}

.select-language__placeholder {
  margin-left: 5px !important;
}

.select-language__input-container {
  padding-left: 0px !important;
}

.select-language__control {
  padding-left: 62px !important;
  min-height: 45px !important;
  height: max-content !important;
}

.profile-languages {
  border: 1px solid #b4b4b4;
  padding: 3%;
  margin-left: 18%;
  width: 58%;
}

.profile-languages ul {
  display: flex;
  flex-direction: column;
}

.profile-languages li {
  list-style: disc outside none;
  display: inline list-item;
  width: 100%;
  padding: 2%;
}

@media screen and (max-width: 480px) {
  .img-controls {
    margin-top: 75%;
    padding: 5%;
  }

  .save-image .react-responsive-modal-modal {
    width: 90% !important;
  }

  .change-password .err {
    width: 90%;
    margin-left: 0px !important;
  }

  .change-password > div {
    width: 100%;
  }
  .logout-modal .react-responsive-modal-modal {
    width: 90% !important;
  }

  #card {
    width: 90%;
  }

  #creditCardNumber {
    font-size: 18px;
  }

  .remove-card-mobile {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .delspan {
    margin-left: 5px;
  }

  .remove-card-mobile img {
    height: 35px;
  }

  .card-container {
    flex-direction: column;
  }

  .payment-details {
    flex-direction: column;
  }

  .payment-details div:nth-child(1) {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    margin-top: -10px;
  }

  .payment-details .btn-container button {
    font-size: 13px !important;
  }

  .profile-languages {
    width: 84%;
    margin-left: 4.5%;
  }
}

@media screen and (min-width: 1450px) {
  .userprofileimg {
    margin-top: -11% !important;
    position: relative;
  }
}

@media screen and (min-width: 481px) and (max-width: 950px) {
  .userprofileimg {
    margin-top: -9% !important;
    position: relative;
  }
  .icon-2 {
    left: 5%;
  }

 
}

@media screen and (min-width: 1700px) {
  .userprofileimg {
    margin-top: -7% !important;
    position: relative;
  }
}
