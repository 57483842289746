.sign-up-container {
  background: url("../../assets/images/BG.svg") no-repeat;
  background-size: 100%;
  min-height: 800px;
}

.chracter-img {
  height: 450px;
  width: 350px;
  object-fit: cover !important;
  border-radius: 50%;
}

.signup-input-field {
  width: 65%;
  padding: 10px;
  padding-left: 70px;
  background: #ffffff;
  border: 1px solid #b4b4b4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 45px;
  line-height: 30px !important;
  align-items: center;
  color: #303030;
}

.eye-icon {
  padding: 10px 52px 69px 0px;
  margin-left: -42px;
  position: absolute;
  cursor: pointer;
}

.create-btn {
  width: 206px;
  height: 40px;

  /* VisaBridge/Tertiary */

  background: #eaf2ef !important;
  border-radius: 31.5px !important;
  font-family: GothamMedium !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 40px !important;
  /* identical to box height, or 222% */

  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;

  /* VisaBridge/Black */

  color: #303030 !important;
}

.horizontal-line-div {
  width: 80%;
  padding-left: 60px !important;
  border-bottom: 1px solid #d5d5d5 !important;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.horizontal-line-div span {
  background: #fff;
  padding: 0 10px;
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #d5d5d5;
}

.bottom-text-signin {
  font-family: GothamBook;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height, or 214% */

  text-align: center;
  letter-spacing: 0.05em;

  /* VisaBridge/Black */

  color: #303030;
}

.green-color {
  color: #3be8b0 !important;
}

.blue-color {
  color: #6a67ce !important;
}

.country-div .css-b62m3t-container {
  width: 65% !important;
}

.country-div .css-26l3qy-menu {
  z-index: 5 !important;
}

.err-w-59 {
  width: 59%;
}
@media only screen and (max-width: 480px) {
  .sign-up-container {
    background: url("../../assets/images/BG-Mobile.svg") no-repeat;
    background-size: 100%;
    min-height: 824px !important;
    text-align: center;
  }
  .chracter-img {
    display: none !important;
  }
  .horizontal-line-div {
    display: none !important;
  }
  .signup-input-field {
    width: 90% !important;
  }
  .bottom-text-signin {
    justify-content: center;
    margin-top: 100px;
  }

  .country-div .css-b62m3t-container {
    display: inline-block !important;
    width: 90% !important;
    text-align: left !important;
  }
  .err {
    text-align: left !important;
    margin-left: 20px !important;
  }
  .err-w-59 {
    width: auto !important;
  }
  .language-div {
    margin-left: 20px;
    width: 100%;
  }
}

.css-14el2xx-placeholder {
  color: #939393 !important;
}

.select__input-container {
  padding-left: 62px !important;
}

.language-div {
  display: flex;
  align-items: center;
}

.language-div .icon {
  padding-bottom: 11px;
}

@media only screen and (481px < width < 958px) {
 
  .sign-up-container {
    background: url("../../assets/images/BG-Mobile.svg") no-repeat;
    background-size: 100%;
    min-height: 824px !important;
    text-align: center;
  }
  .chracter-img {
    height: 350px;
    width: 250px;
    object-fit: cover !important;
    border-radius: 50% !important;
    margin-top: 8rem;
    margin-left: 1rem;
  }
  .horizontal-line-div {
    display: none !important;
  }
  .signup-input-field {
    width: 85% !important;
  }
  .bottom-text-signin {
    justify-content: center;
    margin-top: 100px;
  }
  .country-div{
    width: 94.6% !important;
    margin-left: 1.3rem;
  }
  .visabridge{
    margin-top: -2rem !important;
  }
  .bottom-text-signin{
    margin-top: 3rem;
  }

  .country-div .css-b62m3t-container {
    display: inline-block !important;
    width: 90% !important;
    text-align: left !important;
  }
  .err {
    text-align: left !important;
    margin-left: 20px !important;
  }
  .err-w-59 {
    width: auto !important;
  }
  .language-div {
    margin-left: 20px;
    width: 100%;
  }
}