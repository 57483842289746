.text-container-margin {
  margin-left: 80px;
  margin-right: 80px;
}
.close-img-icon {
  margin-left: 70px;
}
@media only screen and (max-width: 480px) {
  .text-container-margin {
    margin-left: 23px !important;
    margin-right: 23px !important;
  }

  .close-img-icon {
    margin-left: 40px !important;
  }
}

.terms-logo {
  padding-left: 80px !important;
  padding-top: 26px !important;
}

.close-icon {
  padding-top: 50px;
  width: 20px;
}

.terms-heading {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 46px;
  text-transform: capitalize;
  color: #6a67ce;
}

.date-title {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px !important;
  display: flex;
  align-items: center;
  color: #303030;
}

.terms-text {
  font-family: GothamBook;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #303030;
}

.second-terms-title {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

.privacy-content {
  font-size: 18px !important;
  line-height: 24px !important;
  padding: 2%;
}

.privacy-content span {
  display: block;
  margin-top: 20px;
}

@media only screen and (max-width: 480px) {
  .terms-heading {
    font-size: 30px !important;
    line-height: 42px !important;
  }
  .date-title {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .terms-text {
    font-size: 14px !important;
    line-height: 30px !important;
  }
  .second-terms-title {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .terms-logo {
    padding-left: 20px !important;
    padding-top: 40px !important;
  }
}
