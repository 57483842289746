body {
  margin: 0;
}
.MuiButton-root {
  font-family: GothamBook !important;
}

* {
  font-family: GothamBook !important;
}

:root {
  --swiper-navigation-color: #6a67ce;
  --swiper-pagination-color: #6a67ce;
}

@font-face {
  font-family: GothamBold;
  src: url(./assets/fonts/Gotham-Font/GothamBold.ttf);
}

@font-face {
  font-family: GothamLight;
  src: url(./assets//fonts/Gotham-Font/GothamLight.ttf);
}

@font-face {
  font-family: GothamBook;
  src: url(./assets//fonts/Gotham-Font/GothamBook.ttf);
}

@font-face {
  font-family: GothamMedium;
  src: url(./assets//fonts/Gotham-Font/GothamMedium.ttf);
}

@font-face {
  font-family: GothamMedium_1;
  src: url(./assets//fonts/Gotham-Font/GothamMedium_1.ttf);
}

@media only screen and (max-width: 480px) {
  .logo {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .react-responsive-modal-modal {
    width: 315px !important;
  }
}

@media only screen and (min-width: 1025px) {
  .logo {
    margin-left: 55px;
    margin-top: 26px;
  }
  .app-drawer {
    display: none;
  }
}

.d-flex {
  display: flex;
}

.col-padding.MuiListItem-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.text-capitalized {
  text-transform: initial !important;
}
.route-link.MuiButton-root:hover {
  text-decoration: none;
  background-color: transparent !important;
}
.active-link {
  color: #6a67ce !important;
}
.join-btn {
  width: 141px;
  font-family: GothamBold !important;
  height: 40px;
  top: 0px;
  background: #6a67ce !important;
  border-radius: 31.5px !important;
  margin-left: 30px !important;
  color: white !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-0 {
  margin-left: 0px !important;
}
.mt-32 {
  margin-top: 32px !important;
}

.mt-174 {
  margin-top: 174px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-26 {
  margin-top: 26px !important;
}
.mt-28 {
  margin-top: 28px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-20{
  margin-right: 20px !important;
}
.mr-86{
  margin-right: 86px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
.mt-97 {
  margin-top: 97px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.ml-86 {
  margin-left: 86px !important;
}
.pl-6 {
  padding-left: 6px !important;
}
.pl-20 {
  padding-left: 20px !important;
}

.pl-83 {
  padding-left: 83px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-130 {
  margin-top: 130px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.ml-300 {
  margin-left: 300px;
}
.mt-55 {
  margin-top: 55px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.pb-65 {
  padding-bottom: 65px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}
.pt-6 {
  padding-top: 6px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.ml-250 {
  margin-left: 250px !important;
}
.pl-85 {
  padding-left: 85px !important;
}
.mb-170 {
  margin-bottom: 170px !important;
}
.mt-190 {
  margin-top: 190px !important;
}
.mt-230 {
  margin-top: 230px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.w-59 {
  width: 59% !important;
}

.w-100 {
  width: 100% !important;
}
.z-2 {
  z-index: 2 !important;
}
.z-0 {
  z-index: 0 !important;
}
.disply-flex {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.blue-color {
  color: #6a67ce !important;
}

.justify-center {
  justify-content: center !important;
}

/* .Toastify__toast--success {
  
} */
.Toastify__toast {
  background: #ffffff !important;
  box-shadow: 0 1px 9px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 30%) !important;
  border-radius: 10px !important;
  color: #303030 !important;
  font-family: GothamMedium !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

.Toastify__close-button {
  color: #303030 !important;
  padding-top: 15px !important;
}
.react-responsive-modal-modal {
  border-radius: 10px !important;
  text-align: center !important;
  padding: 48px 0px 48px 0px !important;
  width: 638px;
}

.MuiTypography-root {
  font-family: GothamBook !important;
}

::-moz-selection {
  background: #1aafd0;
}
::selection {
  background: #1aafd0;
}
