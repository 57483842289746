.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
}

.logo-container {
  display: flex;
  justify-content: center;
}

.your-job-content {
  background-color: rgba(26, 175, 208, 0.2);
  padding: 75px 30px;
  position: relative;
  overflow: hidden;
}

.stay-connected-box {
  font-family: GothamBook !important;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px 15px;
  height: 40px;
  left: 80px;
  top: 199.06px;
  background: #d6e7ff;
  border-radius: 26.5px;
  font-size: 9px;
  color: #193e6c;
}

.new-label {
  font-family: GothamBook !important;
  background: #a6b4ff;
  color: #ffffff;
  border-radius: 26.5px;
  padding: 7px 25px;
  margin-right: 7px;
}

.find-job-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
}

.find-job-content p {
  font: normal bold 40px/60px GothamBold !important;
  color: #ffffffe8 !important;
}

.find-job-sub-content {
  margin-top: 37px;
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

.social-box-img {
  width: 80% !important;
  height: 320px;
  border-radius: 20px;
  object-fit: cover;
}

.main-homeimage {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.who-are-we-content {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  align-items: center;
  margin-top: 40px;
  color: #1aafd0;
  text-transform: capitalize;
}

.who-are-we-sub-content {
  margin-top: 13px;
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

.sticky-note-img-content {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 38px;
  align-items: center;
  text-transform: capitalize;
}

.sticky-note-img-sub-content {
  font-family: GothamBook;
  font-size: 14px;
  line-height: 26px;
  align-items: center;
  letter-spacing: 0.05em;
  color: #303030;
  font-weight: 700;
  text-transform: capitalize;
}

.sticky-note-img-sub-content ul {
  padding: 0 !important;
}

.testimonial-title {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;

  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6a67ce;
}

.testimonial-sub-title {
  font-family: GothamBook;
  font-size: 16px;
  line-height: 30px;

  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;

  color: #303030;
}

.image-circle {
  border-radius: 50%;
  width: 75px;
  height: 75px;
}

.circular-image-style {
  width: 75x;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
}

.blue-box {
  background: #6a67ce;
  border-radius: 20px;
  margin-left: 50px;
  margin-right: 50px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.blue-box-title {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  /* or 167% */

  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;

  /* VisaBridge/Tertiary */

  color: #eaf2ef;
}

.blue-box-content {
  font-family: GothamBook;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  /* or 186% */

  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;

  color: #ffffff;
}

.blue-box-name {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  /* or 214% */

  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;

  /* VisaBridge/Tertiary */

  color: #eaf2ef;
}

.blue-box-role {
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  /* or 214% */

  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;

  /* VisaBridge/Tertiary */

  color: #eaf2ef;
}

.absolute-circle-img {
  margin-left: auto;
  margin-right: auto;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.circular-blue-image {
  width: 80px;
  height: 80px;
  border: 10px solid white;
  border-radius: 50%;
}

.blue-image-circle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.number-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-indicator {
  background-image: url("../../assets/images/blue-line.png") !important;
  background-position-y: 7px !important;
  background-repeat: no-repeat;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.carousel-indicator img.hide-content {
  display: none !important;
}

.sticky-note-img {
  position: relative;
}

.images-btn {
  font: 700 16px/30px Gotham !important;
  letter-spacing: 0.05em !important;
  color: #303030 !important;
  text-transform: inherit !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  display: none !important; 
  /* { ON the pictures on the home page please take out the words 
  next to them such as “access company data” }
  so I just add this property to remove all content attach with this classname */
}

.upload-cv-btn-position {
  position: absolute !important;
  right: 10%;
  bottom: 10%;
}

.access-data-btn-position {
  position: absolute !important;
  left: -30%;
  top: 5%;
}

.main-image {
  width: 100% !important;
  height: 75% !important;
  border-radius: 30px;
  max-width: 600px !important;
  object-fit: cover !important;
}

.secondSticky {
  display: flex;
  justify-content: flex-end !important;
}

.pre-screen-btn-position {
  position: absolute !important;
  left: 0;
  top: 5%;
}

.quali-visa-btn-position {
  position: absolute !important;
  right: -40%;
  bottom: 5%;
}

.center-element {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome,
  .sticky-note-img-sub-content li {
    display: inline-block;
  }

  .MuiButton-text {
    padding-top: 12px !important;
  }
}

@media screen and (max-width: 1400px) {
  .social-box-img {
    height: 240px;
  }
}

@media screen and (width>1300px) {
  .upload-cv-btn-position {
    right: 5%;
    bottom: 5%;
  }

  .pre-screen-btn-position {
    position: absolute !important;
    left: -5%;
    top: 5%;
  }

  .quali-visa-btn-position {
    position: absolute !important;
    right: -20%;
    bottom: 5%;
  }
}

@media screen and (width < 1374px) {
  .find-job-content p {
    font: normal bold 36px/42px GothamBold !important;
  }
}

@media only screen and (min-width: 1024px) {
  .mb-90-desktop {
    margin-bottom: 90px !important;
  }

  .mobile-mid-div {
    display: none !important;
  }

  .your-job-content {
    padding-left: 85px !important;
  }

  .stay-connected-box {
    padding: 0px 0px 0px 8px;
    width: 453.63px;
    justify-content: initial;
    font-size: 15px;
    
  }

  .center-btn-mob {
    display: none !important;
  }
}

@media screen and (500px < width < 1024px) {
  .mobile-mid-div {
    display: none !important;
  }
  .center-btn-mob {
    display: none !important;
  }
  .quali-visa-btn-position{
    right: 0%;
  }
  .pre-screen-btn-position {
    margin-left: -10% !important;
  }
  .sticky-note-img{
    margin-left:5rem ;
   object-fit: cover !important;
  }
  .access-data-btn-position{
    margin-left: 20% !important;
    
  }
  .sticky-note-img-content{
    text-align: center;
    text-transform: capitalize;
    
  }
  .sticky-note-img-sub-content{
    text-align: left;
  }
 
  .tablet-mid-div{
    display: contents !important;
  }
  .desktop-mid-div{
    display: none !important;
  }
 
}

@media only screen and (max-width: 480px) {
  .main-homeimage {
    height: max-content !important;
    align-items: start !important;
  }

  .images-btn {
    font: 700 10px/18px Gotham !important;
  }

  .sticky-note-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;
  }

  .upload-cv-btn-position {
    right: 3%;
    bottom: 5%;
  }

  .access-data-btn-position {
    left: 2%;
    top: 18%;
  }

  .carousel-grid .makeStyles-buttonWrapper-8 .makeStyles-button-13 {
    margin-left: 17px !important;
  }

  .makeStyles-buttonWrapper-8 {
    margin-right: 18px !important;
  }

  .blue-box {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .blue-box-title {
    font-size: 18px !important;
  }

  .blue-box-content {
    font-size: 12px !important;
  }

  .blue-box-name {
    font-size: 12px !important;
  }

  .blue-box-role {
    font-size: 12px !important;
  }

  .testimonial-title {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .testimonial-sub-title {
    padding-left: 35px !important;
    padding-right: 35px !important;
    margin-bottom: -83px !important;
  }

  .desktop-mid-div {
    display: none !important;
  }

  .image-circle-box {
    display: none !important;
  }

  .find-job-content {
    text-align: center !important;
    font-size: 30px !important;
    line-height: 30px !important;
  }

  .find-job-sub-content {
    text-align: center !important;

    font-size: 12px !important;
    line-height: 30px !important;
  }

  .center-btn-mob {
    text-align: center !important;
    padding-bottom: 30px !important;
  }

  .desktop-btn {
    display: none !important;
  }

  .social-box-img {
    margin-top: 40px !important;
    height: 240px;
    width: 80% !important;
  }

  .your-job-content {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .who-are-we-content {
    font-size: 30px !important;
    line-height: 29px !important;
  }

  .who-are-we-sub-content {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .sticky-note-img-content {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center !important;
    padding-left: 0px !important;
  }

  .sticky-note-img-content li:first-child {
    margin-top: 10px !important;
  }

  .sticky-note-img-content li {
    font-size: 14px !important;
    text-align: left;
    margin-left: 20px;
    color: #303030 !important;
    padding-right: 5px;
  }

  .pre-screen-btn-position {
    position: absolute !important;
    left: 0;
    top: 20%;
  }

  .quali-visa-btn-position {
    position: absolute !important;
    right: 0%;
    bottom: 5%;
  }
}
