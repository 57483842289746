.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
}

.logo-container {
  display: flex;
  justify-content: center;
}
.swiper-button-next{
  color: azure !important;
  opacity: .3;
  margin-right:-6rem ;
  
}
.swiper-button-prev{
  color: azure !important;
  opacity: .3;
  margin-left:-6rem !important;
}

.how-it-works-top-content {
  background-color: rgba(26, 175, 208, 0.2);
  padding-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
}
.stay-connected-box {
  font-family: GothamBook !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px 15px;
  height: 40px;
  left: 80px;
  top: 199.06px;
  background: #d6e7ff;
  border-radius: 26.5px;
  font-size: 9px;
  color: #193e6c;
}

.new-label {
  font-family: GothamBook !important;
  background: #a6b4ff;
  color: #ffffff;
  border-radius: 26.5px;
  padding: 7px 25px;
  margin-right: 7px;
}

@media only screen and (min-width: 1025px) {
  mb-90-desktop {
    margin-bottom: 90px !important;
  }
  .mobile-mid-div {
    display: none !important;
  }
  .how-it-works-top-content {
    padding-left: 85px !important;
  }

  .stay-connected-box {
    padding: 0px 0px 0px 8px;
    width: 453.63px;
    justify-content: initial;
    font-size: 15px;
  }
  .center-btn-mob {
    display: none !important;
  }
}

.find-job-sub-content {
  margin-top: 37px;
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

.who-are-we-sub-content {
  margin-top: 13px;
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

.testimonial-title {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;

  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6a67ce;
}

.testimonial-sub-title {
  font-family: GothamBook;
  font-size: 16px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #303030;
}

.image-circle {
  border-radius: 50%;
  width: 75px;
  height: 75px;
}

.circular-image-style {
  width: 75x;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
}

.absolute-circle-img {
  margin-left: auto;
  margin-right: auto;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.circular-blue-image {
  width: 80px;
  height: 80px;
  border: 10px solid white;
  border-radius: 50%;
}

.blue-image-circle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.number-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-icon-text {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px !important;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6a67ce;
}

.register-icon-sub-text {
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #303030;
}

.prof-img-border {
  border: 30px solid #e5f8ff;
  border-radius: 50%;
  background: #e5f8ff;
  display: inline-flex;
}
.search-img-border {
  border: 30px solid #fff2d2;
  border-radius: 50%;
  background: #fff2d2;
  display: inline-flex;
}
.job-img-border {
  border: 30px solid #ffebed;
  border-radius: 50%;
  background: #ffebed;
  display: inline-flex;
}

.easy-login-btn-position {
  position: absolute !important;
  left: -10%;
  top: 5%;
}

.user-friend-btn-position {
  position: absolute !important;
  left: -20%;
  bottom: 5%;
}

.how-it-works-bottom {
  width: 94%;
  height: 240px;
  border-radius: 20px;
  object-fit: cover;
}

.how-it-works-bottom-1 {
  width: 94%;
  height: 460px;
  border-radius: 20px;
  object-fit: cover;
}

.find-match-btn-position {
  position: absolute !important;
  right: -20%;
  bottom: 5%;
}

.SAVVE-TIME {
  font-size: 26px;
}

.counter-number {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 44px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #6a67ce;
}

.swiper {
  width: 100%;
  height: 100%;
  position: inherit !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 20px !important;
  border-radius: 5px !important;
}

.testimonial-slider {
  height: 300px;
  position: relative !important;
  /* margin-top: 50px; */
}

@media screen and (width >1300px) {
  .user-friend-btn-position {
    left: -15%;
  }
  .find-match-btn-position {
    right: -10%;
  }
}

@media screen and (width < 960px) {
  .how-it-works-bottom-1 {
    width: 80vw;
  }
}

@media only screen and (max-width: 480px) {
  .how-it-works-bottom {
    height: 180px;
  }

  .counter-number {
    font-size: 24px !important;
    line-height: 23px !important;
    padding-left: 20px !important;
  }

  .user-friend-btn-position {
    left: -10%;
  }

  .find-match-btn-position {
    right: -10%;
  }

  .mob-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .how-it-works-bottom-dektop {
    display: none !important;
  }

  .makeStyles-buttonWrapper-8 {
    margin-right: 18px !important;
  }

  .testimonial-title {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .testimonial-sub-title {
    padding-left: 35px !important;
    padding-right: 35px !important;
    margin-bottom: -83px !important;
  }

  .desktop-mid-div {
    display: none !important;
  }
  .image-circle-box {
    display: none !important;
  }

  .find-job-sub-content {
    text-align: center !important;

    font-size: 12px !important;
    line-height: 30px !important;
  }

  .center-btn-mob {
    text-align: center !important;
    padding-bottom: 30px !important;
  }

  .desktop-btn {
    display: none !important;
  }

  .how-it-works-top-content {
    padding-top: 60px !important;
    overflow: hidden !important;
  }

  .who-are-we-sub-content {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
