.forgot-pass-btn {
  width: 310px;
  height: 40px;
  background: #eaf2ef !important;
  border-radius: 31.5px !important;
  font-family: GothamMedium !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 40px !important;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030 !important;
}
