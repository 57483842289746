.contact-us-top-content {
  padding: 60px 30px 60px 80px;
}

.mailto {
  text-decoration: none !important;
  color: #303030;
}

.mailto:hover {
  text-decoration: underline !important;
}

.contact-us-top-content .find-job-content p {
  color: #303030 !important;
}

@media only screen and (min-width: 1025px) {
  .mb-mob {
    margin-bottom: 90px !important;
  }
  .mt-174-desk {
    margin-top: 100px !important;
  }
  .mt-230-dek {
    margin-top: 155px !important;
  }

  .mobile-mid-div {
    display: none !important;
  }
}

.find-job-sub-content {
  margin-top: 37px;
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

@media only screen and (max-width: 480px) {
  .contact-us-top-content {
    padding: 8px 20px 55px 20px !important;
  }

  .text-center-mobile {
    text-align: center !important;
  }
  .mt-174-desk {
    margin-top: 60px !important;
  }
  .form-box {
    padding: 44px 14px 74px 14px !important;
  }
  .pl-mb-20 {
    padding-left: 20px !important;
  }
  .mt-230-dek {
    margin-top: 60px !important;
  }
  .list-text {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  .des-pl-50 {
    padding-left: 0px !important;
  }
  .mb-mob {
    margin-bottom: 30px !important;
  }
  .desktop-img {
    display: none !important;
  }
  .mob-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .how-it-works-bottom-dektop {
    display: none !important;
  }

  .blue-box {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .testimonial-title {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .testimonial-sub-title {
    padding-left: 35px !important;
    padding-right: 35px !important;
    margin-bottom: -83px !important;
  }

  .desktop-mid-div {
    display: none !important;
  }
  .image-circle-box {
    display: none !important;
  }

  .find-job-sub-content {
    text-align: center !important;
    font-size: 14px !important;
    line-height: 30px !important;
    padding: 0px 20px 0px 20px !important;
  }

  .desktop-btn {
    display: none !important;
  }

  .who-are-we-sub-content {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .about-us-img-content {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: left;
    padding-left: 0px !important;
  }
  .about-us-img-sub-content {
    font-size: 14px !important;
    text-align: left;
    padding-left: 0px !important;
  }
}

.who-are-we-sub-content {
  margin-top: 13px;
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

.about-us-img-content {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 38px;
  align-items: left;
  text-transform: capitalize;
}

.about-us-img-sub-content {
  font-family: GothamBook;
  font-size: 16px;
  line-height: 30px;
  align-items: left;
  letter-spacing: 0.05em;
  color: #303030;
}

.testimonial-title {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;

  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6a67ce;
}

.testimonial-sub-title {
  font-family: GothamBook;
  font-size: 16px;
  line-height: 30px;

  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;

  color: #303030;
}

.image-circle {
  border-radius: 50%;
  width: 75px;
  height: 75px;
}

.circular-image-style {
  width: 75x;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
}

.absolute-circle-img {
  margin-left: auto;
  margin-right: auto;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.circular-blue-image {
  width: 80px;
  height: 80px;
  border: 10px solid white;
  border-radius: 50%;
}

.blue-image-circle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.number-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-text {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

.des-pl-50 {
  padding-left: 50px;
}

.our-team-img {
  width: 182px;
  height: 182px;
  border-radius: 50% !important;
  object-fit: cover;
}
.our-team-box {
  background: #eaf2ef;
  padding: 40px 0px 150px 0px;
}

.margin-to-cut-box {
  margin-top: -95px !important;
}

.our-team-name {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #303030;
}

.our-team-desc {
  font-family: GothamBook;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #303030;
}

.connect-blue-box {
  background: #6a67ce;
  border-radius: 20px;
  padding: 20px 0px 0px 30px;
}

.get-in-touch-btn {
  width: 141px;
  font-family: GothamBold !important;
  height: 40px;
  top: 0px;
  background: #6a67ce !important;
  border-radius: 31.5px !important;
  color: #fff !important;
}

.contact-us-title {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 70px;
  align-items: center;
  color: #6a67ce;
}

.icon {
  padding: 10px 0px 0px 24px;
  width: 24px;
  position: absolute;
  z-index: 9;
}

::-webkit-input-placeholder {
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  align-items: center;
  color: #303030;
}

.input-field {
  width: 100%;
  padding: 10px;
  padding-left: 70px;
  background: #ffffff;
  border: 1px solid #b4b4b4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 45px;
  line-height: 30px;
  align-items: center;
  color: #303030;
}

.form-box {
  background: #eaf2ef;
  border-radius: 20px;
  padding: 44px 14px 74px 27px;
}

.contact-us-phone {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  letter-spacing: 0.05em;
  color: #303030;
  padding-top: 3px;
}

.phone-icon {
  width: 20px;
  padding-right: 18px;
}

.css-1s2u09g-control {
  height: 45px !important;
  border: 1px solid #b4b4b4 !important;
  outline: none !important;
}
.css-1s2u09g-control:hover {
  border: 1px solid #b4b4b4 !important;
}
.css-1pahdxg-control:hover {
  border: 1px solid #b4b4b4 !important;
  outline: none !important;
  box-shadow: white !important;
}

.css-1pahdxg-control {
  border: 1px solid #b4b4b4 !important;
  box-shadow: none !important;
  background-color: white !important;
}
.css-14el2xx-placeholder {
  margin-left: 64px !important;
  margin-right: 2px !important;
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px !important;
  align-items: center;
  color: #303030 !important;
}

.css-tlfecz-indicatorContainer {
  color: #303030 !important;
  cursor: pointer !important;
}

.css-1gtu0rj-indicatorContainer {
  height: 45px !important;
}

.css-1pndypt-Input {
  padding-left: 64px !important;
  margin-right: 2px !important;
  font-family: GothamBook !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px !important;
  align-items: center;
  color: #303030 !important;
}

.css-1gtu0rj-indicatorContainer {
  height: 43px !important;
  color: #303030 !important;
  padding: 8px !important;
  -webkit-align-items: center !important;
  cursor: pointer !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-319lph-ValueContainer {
  cursor: text !important;
}

.css-yt9ioa-option {
  cursor: pointer !important;
}
.css-1n7v3ny-option {
  cursor: pointer !important;
}

.css-qc6sy-singleValue {
  padding-left: 64px !important;
  font-family: GothamBook !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px !important;
  align-items: center;
  color: #303030 !important;
}

.err {
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px !important;
  color: #fc636b;
  padding-top: 3px;
  margin-bottom: -14px;
}

.error-border {
  border: 1px solid #fc636b !important;
  border-radius: 5px !important;
}
