.banner {
  margin-top: 6px;
  height: 200px !important;
  /* background: #eaf2ef; */
  background: #c5c4f5;
  justify-content: center;
  align-items: center;
  /* text-transform: capitalize; */
}

.banner-heading {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  margin: 0px;
  margin-bottom: 5px;
  font-family: "GothamBook !important";
}

.banner-subheading {
  margin-top: 0px;
  font-weight: normal;
  font-style: normal;
  color: #303030;
  font-size: 16px;
  line-height: 20px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  
}
.subheading-titlecase{
  text-transform: capitalize;
}
.main-container {
  padding: 5%;
}

.sidebar {
  width: 100%;
  background: #eaf2ef;
  padding: 5% 4%;
  border-radius: 10px;
}

.filter-heading {
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.main-container .select__placeholder {
  color: #303030 !important;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.05em;
}

.main-container .signup-input-field {
  width: 100% !important;
}

.main-container input::placeholder {
  color: #303030 !important;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.05em;
}

.filter-heading1 {
  font-size: 14px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em !important;
}

.main-container .MuiSlider-root {
  color: #6a67ae !important;
}

.main-container .MuiSlider-thumb {
  background-color: white;
  border: 1px solid #6a67ce;
  height: 14px;
  width: 14px;
}

.main-container .MuiSlider-valueLabel {
  bottom: -45px;
  top: 30px;
  left: -23px;
  color: #d4e0f6;
}

.main-container .MuiSlider-valueLabel > span {
  border-radius: 0;
  transform: rotate(0deg);
  width: calc(100% + 10px);
  min-width: 67px;
}

.main-container .MuiSlider-valueLabel > span > span {
  transform: rotate(0deg);
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #6a67ae;
}

.main-container .MuiSlider-rail {
  height: 5px;
}

.main-container .MuiSlider-track {
  height: 5px;
}

.job-list {
  padding: 0 2%;
  margin-top: -6px !important;
}

.job-list-heading {
  font-size: 14px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em !important;
  font-weight: 500 !important;
  color: #303030 !important;
  display: flex;
  align-items: center;
  margin-left: 2% !important;
}

.job-list-container {
  padding: 0 2%;
}

.job-list-item {
  padding: 18px 23px;
  height: 135px;
  box-shadow: 3px 4px 0px 0px #b4b4b440;
  border-radius: 10px;
  background: #fff;
  width: 100%;
  margin-bottom: 24px;
  cursor: pointer !important;
}

.job-header {
  display: flex;
  justify-content: space-between;
}

.job-list-item-left {
  display: flex;
  align-items: center;
  font-size: 20px !important;
  line-height: 25px !important;
  letter-spacing: 0.02em !important;
  font-weight: bold !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.job-list-item-body {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.job-list-item-body > div > span {
  margin-left: 10px;
  vertical-align: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ml-10 {
  margin-left: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.job-list-item-footer {
  margin-top: 0px;
}

.MuiChip-label {
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
}

.job-list-item-footer .full-time {
  background: rgba(106, 62, 231, 0.1) !important;
  color: rgb(106, 103, 206) !important;
}

.job-list-item-footer .contract {
  background: #303030 !important;
  color: #fff !important;
}

.job-list-item-footer .part-time {
  background: rgba(255, 228, 88, 0.3) !important;
  color: rgba(255, 218, 20, 1) !important;
}

.job-list-item-footer .internship {
  background: #ff4b4b33 !important;
  color: #fc636b !important;
}

.job-list-item-footer .freelance {
  background: #ff89d04d !important;
  color: #ff2dab !important;
}

.job-list-item-footer .temporary {
  background: #80fb754d !important;
  color: #14a800 !important;
}

.pe-none {
  pointer-events: none !important;
}

.job-list-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  flex-direction: column;
}

.show-more {
  color: #6a67ce;
  text-decoration: underline;
}

.progress-bar {
  width: 40%;
}

.modal-heading {
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em !important;
  font-weight: 700 !important;
  color: #6a67ce !important;
  margin-bottom: -5px !important;
}

.modal-sub-heading {
  font-size: 14px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em !important;
  font-weight: 500 !important;
}

.save-filter-modal .btn-container {
  overflow-x: hidden !important;
}

.filter-input-wrapper .MuiFormControl-root.MuiTextField-root {
  width: 50% !important;
}

.save-filter .react-responsive-modal-modal {
  padding: 35px 0px 10px 0px !important;
  height: max-content !important;
}

.main-container.MuiGrid-container {
  width: 100% !important;
}

.delete-filter {
  color: #6a67ce;
  text-decoration: underline;
  font-size: 16px !important;
  line-height: 30px !important;
  vertical-align: super;
  margin-left: 5px;
}

.filter-name {
  display: flex;
  align-items: center;
  font-size: 20px !important;
  line-height: 30px !important;
  letter-spacing: 0.02em !important;
  font-weight: bold !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.remove-filter-container {
  align-self: center;
  cursor: pointer;
  padding-left: 10% !important;
}

.job-show {
  display: flex;
  justify-content: center;
  padding-top: 5%;
}

.saved-filter .main-container {
  padding-top: 0% !important;
}

.main-banner {
  height: 160px !important;
}

.filter-date {
  margin-top: 0px;
  font-weight: normal;
  font-style: normal;
  color: #303030;
  font-size: 16px;
}

.saved-jobs .job-list-item {
  width: inherit !important;
}

.saved-jobs .main-container {
  padding-top: 0% !important;
}

.saved-filters .job-list-item {
  width: inherit !important;
  height: 91px !important;
}

.loader.job-list-item {
  width: inherit !important;
  height: 91px !important;
}

.dashboard-drawer .col-padding.MuiListItem-root {
  width: max-content !important;
}

.no-data-text {
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: 0.05em !important;
  font-weight: 700 !important;
  color: #6a67ce !important;
  margin-bottom: -5px !important;
  padding-top: 7vh;
}

.no-data-container {
  height: 25vh;
}

.main-container .select__menu {
  overflow-y: auto;
  max-height: 135px;
}

.spider_name {
  display: flex;
  justify-content: flex-start;
  max-width: 50%;
}

.job-details .spider_name {
  max-width: 100%;
}

.job-img {
  height: 23px;
}

.job-details {
  overflow: hidden;
}

/* Media Query */
@media only screen and (max-width: 480px) {
  .banner {
    height: max-content !important;
    background: #ecf4ff;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 20px;
    background: #c5c4f5;
    /* text-transform: capitalize; */
  }
  .banner-heading {
    font-size: 18px;
    line-height: 30px !important;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    text-align: start;
  }
  .banner-subheading {
    font-size: 12px;
    padding: 0 2% !important;
    display: flex !important;
    justify-content: flex-start !important;
    width: 100% !important;
  }
  .subheading-titlecase{
    text-transform: capitalize;
  }
  .subheading-low {
    margin-left: -10% !important;
  }

  .main-container {
    padding: 2%;
  }

  .find-jobs {
    display: flex;
    justify-content: space-between;
  }

  .banner-heading span {
    position: absolute;
    right: 4%;
  }

  .find-job-container.back-color .MuiDrawer-paper {
    width: 80% !important;
    height: 100% !important;
    right: 0px !important;
    left: 20% !important;
    border-radius: 0px 0px 0px 50px !important;
  }

  .sidebar {
    width: inherit;
  }

  .sidebar .signup-input-field {
    width: 100% !important;
    padding-left: 63px !important;
  }

  .main-container input::placeholder {
    font-size: 12px !important;
    line-height: 30px !important;
    color: #303030 !important;
  }

  .main-container .select__placeholder {
    font-size: 12px !important;
    line-height: 30px !important;
  }

  .date-posted .MuiFormControlLabel-root {
    width: 80% !important;
  }

  .main-container .signup-input-field::placeholder {
    color: #303030 !important;
  }

  .job-list {
    margin-top: 5px !important;
  }

  .job-list-heading {
    font-size: 12px !important;
  }

  .job-list-item-left {
    font-size: 16px !important;
    line-height: 30px !important;
    width: 50%;
  }

  .job-img {
    height: 23px;
  }

  .filter-heading {
    font-size: 12px !important;
    margin-bottom: 0px !important;
  }

  .spider_name {
    display: flex;
    justify-content: flex-start;
    max-width: 50%;
  }

  .job-list-item-body {
    margin-top: 5px;
    justify-content: flex-start;
  }

  .job_address {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .job-list-item-footer {
    margin-top: 5px;
  }

  .save-filter-modal .btn-container {
    padding-bottom: 20px !important;
  }

  .filter-input-wrapper .MuiFormControl-root.MuiTextField-root {
    width: 90% !important;
  }

  .filter-footer {
    padding-bottom: 5% !important;
  }

  .filter-name {
    display: flex;
    align-items: center;
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .delete-filter {
    font-size: 13px !important;
  }

  .filter-date {
    font-size: 14px;
  }

  .saved-filter .MuiGrid-container {
    padding-top: 4% !important;
  }

  .remove-filter-container {
    align-self: flex-start;
  }

  .job-show {
    justify-content: flex-start;
    padding-top: 2%;
    padding-left: 2%;
    margin-bottom: -7%;
  }

  .saved-jobs .job-show {
    margin-bottom: -3% !important;
  }

  .job-list-item {
    height: max-content !important;
    width: inherit !important;
  }

  .saved-filters .job-list-item {
    height: max-content !important;
  }

  .saved-filter .remove-filter-container {
    padding: 10px 0px !important;
    display: flex;
    justify-content: center;
  }

  .saved-filter .delete-filter {
    line-height: 30px !important;
  }
}


