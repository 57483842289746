.subscription-container {
  background: #ecf4ff;
  background-size: 100%;
  min-height: 815px;
  padding-left: 80px;
}

.subscr-logo-div {
  padding-top: 40px;
}

.subscription-title {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  align-items: center;
  color: #6a67ce;
}

.subscription-content {
  font-family: GothamMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  align-items: center;
  letter-spacing: 0.02em;
  /* text-transform: capitalize; */
  color: #303030;
}

.plan-box {
  background: #ffffff;
  padding: 66px 0px 5px 49px; 
  box-shadow: 1px 2px 20px 1px rgba(180, 180, 180, 0.15);
  border-radius: 20px;
  margin-right: 80px;
  margin-top: 50px;
}

.sub-list-text {
  font-family: GothamBook;
  font-size: 20px;
  line-height: 23px;
  align-items: center;
  color: #303030;
}

.currency-title {
  font-family: GothamBook;
  font-size: 8px !important;
  line-height: 30px;
  align-items: center;
  letter-spacing: 0.05em;
  color: #303030;
}

.plan-amount {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 46px;
  align-items: center;
  text-transform: capitalize;
  color: #303030;
}

.plan-amount span {
  font-size: 30px;
  font-family: GothamLight;
}

.subscribe-btn {
  font-family: GothamBold !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  /* line-height: 30px !important; */
  text-align: center;
  letter-spacing: 0.05em;
  color: #303030 !important;
  width: 159px;
  height: 40px;
  background: #6a67ce !important;
  border-radius: 31.5px !important;
  margin-right: 24px !important;
  color: #fff !important;
}

.have-any-btn {
  font-family: GothamBold !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 40px !important;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6a67ce !important;
  width: 241px;
  height: 40px;
  border: 1px solid #6a67ce !important;
  box-sizing: border-box;
  border-radius: 31.5px !important;
}

.apply-btn {
  font-family: GothamBold !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 30px !important;
  text-align: center;
  letter-spacing: 0.05em;
  color: #303030 !important;
  width: 97px;
  height: 40px;
  background: #6a67ce !important;
  border-radius: 31.5px !important;
  margin-right: 24px !important;
  color: #fff !important;
}

.cancel-btn {
  font-family: GothamBold !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 40px !important;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6a67ce !important;
  width: 97px;
  height: 40px;
  border: 1px solid #6a67ce !important;
  box-sizing: border-box;
  border-radius: 31.5px !important;
}

.have-any-btn:hover {
  text-decoration: none;
  background: none !important;
}

.make-payment-btn {
  width: 204px !important;
}

.payment-no-plan-box {
  padding: 66px 0px 75px 49px;
  border-radius: 20px;
  margin-right: 80px;
  margin-top: 50px;
  text-align: left;
}

.card-showcase {
  padding: 15px 10px;
  border: 1px solid rgba(73, 90, 90, 0.4);
  width: fit-content;
  border-radius: 2px;
  font-family: "GothamBook";
  font-size: 13px;
  margin-top: 20px;
}
.stripe-logo{
text-align: end;

}
@media only screen and (max-width: 480px) {
  .stripe-logo{
    text-align: center;
  }
  .add-coupon-input-field {
    width: 230px !important;
  }

  .subscription-container {
    text-align: center;
    padding-left: 0px;
  }

  .subscription-title {
    font-size: 28px !important;
    line-height: 40px !important;
    margin-top: 55px !important;
  }

  .subscription-content {
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 0px 30px 0px 30px;
    margin-top: 10px !important;
  }

  .plan-box {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;

    padding: 16px 41px 0px 47px;
    margin-bottom: 74px;
  }

  .currency-title {
    font-size: 10px !important;
  }

  .plan-amount {
    font-size: 28px !important;
    line-height: 40px !important;
  }

  .plan-amount span {
    font-size: 16px !important;
  }

  .sub-list-text {
    font-size: 14px;
    line-height: 30px !important;
    padding-top: 5px;
  }

  .btn-box {
    text-align: center !important;
  }

  .subscribe-btn {
    margin-right: 0px !important;
  }

  .have-any-btn {
    margin-top: 14px !important;
  }

  .subscr-logo-div {
    padding-top: 100px !important;
  }
}

.react-responsive-modal-closeButton {
  display: none !important;
}

.add-coupon-input-field {
  width: 414px;
  padding: 8px;
  padding-left: 17px;
  background: #ffffff;
  border: 1px solid #b4b4b4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  font-family: GothamBook;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 45px;
  line-height: 30px !important;
  align-items: center;
  color: #575555;
}

.coupon-modal-title {
  font-family: GothamBold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px !important;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6a67ce !important;
}

.coupon-modal-sub-title {
  font-family: GothamBook;
  font-size: 16px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #303030 !important;
}

.w-97px {
  width: 97px !important;
}

.cancel-btn:hover {
  text-decoration: none;
  background: none !important;
}

.subscription-container .header-div {
  margin-top: 35px;
}

.subscription-container .saved-cards {
  background: #fff;
  margin-left: -5%;
}

.select-card {
  display: flex;
  justify-content: space-between;
}

.cards-list .MuiFormGroup-root {
  flex-direction: row !important;
}

.card-select-modal .react-responsive-modal-modal {
  width: inherit !important;
}

.select-card-top {
  display: flex;
  justify-content: space-between;
}

.select-card-top p {
  margin-left: 40%;
}
